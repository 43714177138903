import React from 'react';
import content from './content';
import './App.css';

function Blurbs(props) {
  const blurbs        = content.blurbs || [],
        blurbsMarkup  = [],
        blurbsMarkup1 = [],
        blurbsMarkup2 = [],
        blurbsLength  = blurbs.length;

  for(let i = 0; i < blurbsLength; i++) {
    const item   = blurbs[i] || {},
          blurb  = item.blurb || '',
          author = item.author || '',
          etc    = item.etc || '';

    if (i < (blurbsLength / 2)) {
      blurbsMarkup1.push(
        <div key={`blurb${i}`} className="blurb">
          <img src="quotationmarks.svg" className="quotationmarks" alt="Quotation Marks" />
          <p dangerouslySetInnerHTML={{ __html: (blurb + '&#8221;') }} />
          <p className="blurb--author"><span>{author}</span>{etc}</p>
        </div>
      ) 
    } else {
      blurbsMarkup2.push(
        <div key={`blurb${i}`} className="blurb">
          <img src="quotationmarks.svg" className="quotationmarks" alt="Quotation Marks" />
          <p dangerouslySetInnerHTML={{ __html: (blurb + '&#8221;') }} />
          <p className="blurb--author"><span>{author}</span>{etc}</p>
        </div>
      ) 
    }

  }
 
  blurbsMarkup.push(<div key="blurbcolumnone" className="blurbholder">{blurbsMarkup1}</div>)
  blurbsMarkup.push(<div key="blurbcolumntwo" className="blurbholder">{blurbsMarkup2}</div>)

  return blurbsMarkup
}

function App() {
  return (
    <div className="App">

      <div className="Slide__wrap one">
        <div className="Slide">
          <div className="viewport-wrap">
            <div className="content one">
              <img src={'A-Strong-and-Steady-Pulse__L.jpg'} className="App-logo" alt="logo" />
              <div className="floater">
                <p>Coming 9/21/2021</p>
                <h2>A Strong & Steady Pulse</h2>
                <h3>Stories from a Cardiologist</h3>
                <p>Preorders taken on the<br />University of Alabama Press <a href="http://www.uapress.ua.edu/product/Strong-and-Steady-Pulse,7667.aspx">Website</a></p>
              </div>

              <p className="by">By Gregory D. Chapman, MD</p>
            </div>
          </div>
          <img src={'ekg.svg'} className="ekg hide-desktop" alt="divider" />
        </div>
      </div>

      <div className="Slide__wrap two">
        <div className="Slide Flip">
          <div className="content two">
            <img src={'ekg-white.svg'} className="ekg hide-mobile" alt="divider" />
            <img src='chapman--L.jpg' className="author hide-desktop" alt="Dr. Greg Chapman, in his study"/>
            <h3>
              A seasoned cardiologist shares his experiences, opinions, and recommendations about heart disease and other cardiac problems 
            </h3>
            <div className="inset">
              <div className="sticky-wrap hide-mobile">
                <img src='chapman--L.jpg' className="author" alt="Dr. Greg Chapman, in his study" />
              </div>
              <p>
                <span><em>A Strong & Steady Pulse: Stories from a Cardiologist</em></span> provides an insider’s perspective on the field of cardiovascular medicine told through vignettes and insights drawn from Gregory D. Chapman’s three decades as a cardiologist and professor of medicine. In twenty-six bite-sized chapters based on real life patients and experiences, Chapman provides an overview of contemporary cardiovascular diseases and treatments, illuminating the art and science of medical practice for lay audiences and professionals alike.
              </p>
              <p>
                Chapman’s inspiration for this memoir was a wish to provide general readers with a better understanding of cardiac disease and its contributing factors in modern life and to provide insights on the diagnostic process, medical decision making, and patient care. Each chapter presents a patient and their initial appearance, described in clear detail as Chapman gently walks us through his evaluation and the steps taken to determine the underlying problem. Chapman’s stories are about real people dealing with life and death situations—including the physicians, nurses, medical students, and other team members who try to save lives in emergent, confusing conditions.
              </p>
              <p>
                The sometimes hard-won solutions are informed by Chapman’s past experiences as an intern and resident in Manhattan during the AIDS epidemic, as a postdoctoral fellow at Duke University in the 1990s, and in practice in Nashville and Birmingham, as well as new technology and cutting-edge research. Topics addressed include the recognition and management of heart attack, heart failure, arrhythmia, valvular heart disease, cardiac transplantation, broken heart syndrome, hypertension, and the depression some people experience after a heart attack, as well as who benefits most from statin drugs, the Apple Watch ECG feature, and oral anticoagulants. Finally, the emergence of the Covid-19 virus and its disruption of normal hospital routines is addressed in an epilogue.
              </p>
              <p>
                <b>Gregory D. Chapman</b>, MD, FACC, is in his third decade of practicing medicine and cardiology. A professor of medicine at the University of Alabama at Birmingham, he has published research papers in <em>Circulation</em>, <em>Circulation Research</em>, and the <em>American Journal of Cardiology</em>. His commentary and letters have appeared in  the <em>American Journal of Medicine</em>, the <em>New England Journal of Medicine</em>, and the <em>New York Times</em>. He is a recipient of the Patient Experience Excellence Award at UAB and has received an Outstanding Teaching Award from the UAB Department of Medicine. He is listed in Best Doctors in America. A native of Hokes Bluff, Alabama, he lives in Birmingham.
              </p>
            </div>
          </div>
        </div>
      </div>


      <div className="Slide__wrap three">
        <div className="Slide">
          <div className="content three">
            <img src={'ekg.svg'} className="ekg hide-mobile" alt="divider" />
            <h3>Some advance praise for <em>A Strong & Steady Pulse</em>:</h3>
            <Blurbs/>
            <img src={'ekg.svg'} className="ekg hide-desktop" alt="divider" />

          </div>
          <div className="copyright">
            <p>Copyright © 2020 Dr. Gregory Chapman</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
