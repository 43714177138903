import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { errorMonitor } from 'events';



ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
var pulses = 0;
function wireUpSticky() {
  pulses++;
  var vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
  var vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
  var slideWraps = document.querySelectorAll('.Slide__wrap');

  var slideHeights = [];

  for(var i =0; i < slideWraps.length; i++) {
    var slideWrap = slideWraps[i];

    if(vw < 1040) {
      slideWrap.setAttribute('style', '');
      continue;
    }
    var slide = slideWrap.querySelector(".Slide");

    var slideHeight = slide.getBoundingClientRect().height;
    //console.log("slideHeights +"+i+": "+slideHeight);
    slideHeights.push(slideHeight);

    var wrapHeight;

    switch (i) {
      case 1:
        wrapHeight = slideHeights[0] + slideHeights[1] + (slideHeights[0] - vh);
        break;
      case 2:
        wrapHeight = slideHeights[0] + slideHeights[1] + slideHeights[2] + (slideHeights[0] - vh);
        break;
      default:
        wrapHeight = slideHeights[0];
    }
    slideWrap.setAttribute('style', 'height: ' + wrapHeight + 'px')
  }

  setTimeout(wireUpSticky, pulses < 4 ? 750 : 2000);
}

var callback = function () {
  wireUpSticky();
};

if (
  document.readyState === "complete" ||
  (document.readyState !== "loading" && !document.documentElement.doScroll)
) {
  callback();
} else {
  document.addEventListener("DOMContentLoaded", callback);
}