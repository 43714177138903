module.exports = {
blurbs: [
{
author: 'John W. Stewart III',
blurb: '<em>A Strong and Steady Pulse</em> presents a collection of true-to-life anecdotes that will delight the health professional, medical aficionado, or anyone who loves a good story. Dr. Chapman manages to inform the fascinating clinical and scientific experiences of a life dedicated to cardiology with a compelling thread of humanity and good humor that renders this book at once instructional and universal. Both entertaining and enlightening, reading this book will make you smarter and more human---a welcome, feel good read with substance!',
etc: ', PhD, President, the University of Montevallo'
},
{
author: 'T.K. Thorne',
blurb: 'Educating, entertaining, and surprising. You will never look at your heart the same again.',
etc: ', author of Last Chance for Justice'
},
{
author: 'F. Andrew Gaffney',
blurb: 'Dr. Chapman, through his patients’ histories, shares his experiences and insights across three decades of caring for patients with cardiovascular disease. I highly recommend his well-written, easy-to-read narrative not only to health care providers and their students, but also to patients. All will benefit from a better understanding of their care.',
etc: ', MD, Professor of Medicine, Vanderbilt University'
},
{
author: 'Rachel Waide',
blurb: 'In <em>A Strong and Steady Pulse</em>, Greg Chapman balances medical information with good storytelling. There is a chapter on COVID-19 and its impact on cardiac patients. There are also deeply touching narratives about a mother who has just delivered her first child and finds herself experiencing heart palpitations, fearing she will not live to see her son into adulthood, and about a son who is outraged over the thought of his mother being prescribed a statin. Simply put, the book is real life. It offers a bird’s-eye view of cardiology practice and a diverse community of patients who are battling heart disease and other ailments. The author’s balance between medicine and humanity is impeccable; readers will find this one compelling.',
etc: ', JD, Tupelo, Mississippi attorney'
},
{
author: 'Lisa Willett',
blurb: 'Dr. Chapman uses patient stories as a powerful teaching tool for cardiology concepts. More importantly, his stories remind us of the privilege and responsibility of caring for patients. It is a must read for all who care for patients, especially in the field of general medicine and cardiology.',
etc: ', MD, Professor of Medicine, Vice Chair for Medical Education, and Internal Medicine Residency Program Director, the University of Alabama at Birmingham.'
},
{
author: 'Sumanth D. Prabhu',
blurb: 'Medicine is both an art and a science. These two realms merge regularly in our conversations and interactions with patients and their families, often times when we least expect it. In this wonderful book of medical vignettes, Dr, Chapman, an interventional cardiologist, recounts poignant stories about his patients and his life that link the art and science of medicine, and in the end, generate wisdom that will be appreciated by patients and physicians alike.',
etc: ', MD, Professor of Medicine, Director, Division of Cardiovascular Disease, University of Alabama at Birmingham.'
},
{
author: 'Martha Lavender',
blurb: 'Dr. Chapman provides readers with an inside view of medical practice in a way that shares the “science of medicine” while personalizing the stories of the people living through serious medical conditions. Every scenario has an interesting twist with a medical response that is specific to the patient or is compared with others who have had similar experiences. These stories epitomize the art and science of medicine and why we respect individuals, like Dr. Chapman, who truly care about their patients. I recommend this book for aspiring health professionals as well as those who need to reach back to rediscover why they chose to serve in the health care field.',
etc: ', PhD, Fellow, the American Academy of Nursing, and Member, the State of Alabama Nursing Hall of Fame'
},
{
author: 'Marc G. Cribbs',
blurb: '<em>A Strong and Steady Pulse</em> is as instructive as it is fun to read. It wonderfully reflects Dr. Chapman’s wit and charm.',
etc: ', MD, Director, Alabama Adult Congenital Heart Program, and Director of the UAB Comprehensive Pregnancy and Heart Program.'
}
]
}